import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Subject, takeUntil } from 'rxjs';
import { CompetitionService } from './competition.service';
import { Competition } from './competition.types';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { UploadService } from '../core/upload.service';

@Component({
  selector: 'app-competitions',
  templateUrl: './competitions.component.html',
  styleUrl: './competitions.component.scss'
})
export class CompetitionsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  public competition: Partial<Competition> = {};
  public competitions: Competition[] = [];
  public competitionDialog: boolean = false;
  public competitionDetailDialog: boolean = false;

  public refereeOptions: SelectItem[] = [];
  public scoringRulesOptions: SelectItem[] = [];
  public participantsOptions: SelectItem[] = [];

  public competitionForm: FormGroup = new FormGroup({
    competitionName: new FormControl(null, { validators: Validators.required }),
    description: new FormControl(null),
    startDate: new FormControl(null, { validators: Validators.required }),
    endDate: new FormControl(null, { validators: Validators.required }),
    scoringRule: new FormControl(null, { validators: Validators.required }),
    referees: new FormControl(null, { validators: Validators.required }),
    competitors: new FormControl(null),
    active: new FormControl(true, { validators: Validators.required })
  });

  constructor(
    private competitionService: CompetitionService,
    private readonly uploadService: UploadService,
    private readonly authService: AuthService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit() {
    this.competitionService.getCompetitions().subscribe(result => {
      if (this.roleName === 'Referee') {
        this.competitions = result
          .filter((competition: any) => {
            const refereeIds = competition.referees.split('|');
            return refereeIds.some((el: number) => {
              return this.authService.loggedInUser?.id == el;
            });
          })
          .map((item: any) => ({
            ...item,
            imgData: `${environment.s3ApiURL}/${item.s3Data}`
          }));
      } else {
        this.competitions = result.map((item: any) => ({
          ...item,
          imgData: `${environment.s3ApiURL}/${item.s3Data}`
        }));
      }
    });

    this.competitionService
      .getReferees()
      .pipe(takeUntil(this.destroy$))
      .subscribe(referees => {
        this.refereeOptions = referees.map(ref => ({
          value: ref.id,
          label: `${ref.firstName} ${ref.lastName}`
        }));
      });
    this.competitionService
      .getScoringRules()
      .pipe(takeUntil(this.destroy$))
      .subscribe(scoringRules => {
        this.scoringRulesOptions = scoringRules.map(ref => ({
          value: ref.id,
          label: `${ref.ruleName}`
        }));
      });
    this.competitionService
      .getParticipants()
      .pipe(takeUntil(this.destroy$))
      .subscribe(participants => {
        this.participantsOptions = participants.map(ref => ({
          value: ref.id,
          label: `${ref.firstName} ${ref.lastName}`
        }));
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openCompetitionDetail(competition: Competition, dialog: Dialog) {
    this.competition = competition;
    dialog.maximize();
    this.competitionDetailDialog = true;
  }

  public startCompetition(
    event: PointerEvent | MouseEvent,
    competitionId: number
  ) {
    this.confirmationService.confirm({
      key: 'start-competition',
      target: event.target || new EventTarget(),
      message:
        'Esti sigur ca vrei sa activezi posibilitatea de a incarca capturi?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Nu',
      accept: () => {
        this.competitionService
          .startCompetition(competitionId, new Date().toLocaleTimeString())
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Competitia a inceput!',
                life: 3000
              });
              this.competitionService.getCompetitions().subscribe(result => {
                if (this.roleName === 'Referee') {
                  this.competitions = result
                    .filter((competition: any) => {
                      const refereeIds = competition.referees.split('|');
                      return refereeIds.some((el: number) => {
                        return this.authService.loggedInUser?.id == el;
                      });
                    })
                    .map((item: any) => ({
                      ...item,
                      imgData: `${environment.s3ApiURL}/${item.s3Data}`
                    }));
                } else {
                  this.competitions = result.map((item: any) => ({
                    ...item,
                    imgData: `${environment.s3ApiURL}/${item.s3Data}`
                  }));
                }
              });
            },
            error: error => {
              console.log(error.response);
            }
          });
      },
      reject: () => {
        console.log('rejected');
      }
    });
  }

  public stopCompetition(
    event: PointerEvent | MouseEvent,
    competitionId: number
  ) {
    this.confirmationService.confirm({
      key: 'stop-competition',
      target: event.target || new EventTarget(),
      message:
        'Esti sigur ca vrei sa dezactivezi posibilitatea de a incarca capturi?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Nu',
      accept: () => {
        this.competitionService
          .stopCompetition(competitionId, new Date().toLocaleTimeString())
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Competitia s-a incheiat!',
                life: 3000
              });
              this.competitionService.getCompetitions().subscribe(result => {
                if (this.roleName === 'Referee') {
                  this.competitions = result
                    .filter((competition: any) => {
                      const refereeIds = competition.referees.split('|');
                      return refereeIds.some((el: number) => {
                        return this.authService.loggedInUser?.id == el;
                      });
                    })
                    .map((item: any) => ({
                      ...item,
                      imgData: `${environment.s3ApiURL}/${item.s3Data}`
                    }));
                } else {
                  this.competitions = result.map((item: any) => ({
                    ...item,
                    imgData: `${environment.s3ApiURL}/${item.s3Data}`
                  }));
                }
              });
            },
            error: error => {
              console.log(error.response);
            }
          });
      },
      reject: () => {
        console.log('rejected');
      }
    });
  }

  public competitionNotStarted(el: any) {
    return new Date(el.startDate) > new Date();
  }

  public resetCompetition(
    event: PointerEvent | MouseEvent,
    competitionId: number
  ) {
    this.confirmationService.confirm({
      key: 'reset-competition',
      target: event.target || new EventTarget(),
      message: 'Esti sigur ca vrei sa resetezi competitia?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Nu',
      accept: () => {
        this.competitionService.resetCompetition(competitionId).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Competitia a fost resetata!',
              life: 3000
            });
          }
        });
      }
    });
  }

  public openNewCompetition(dialog: Dialog) {
    this.competition = {};
    dialog.maximize();
    this.competitionDialog = true;
  }
  public closeCompetitionDialog() {
    this.competition = {};
    this.competitionDialog = false;
  }
  public saveCompetition() {
    const body = this.competitionForm.getRawValue();
    this.competitionService
      .createCompetition({
        ...body,
        referees: body.referees.join('|'),
        competitors: body.competitors.join('|'),
        createDate: new Date(),
        creator: this.authService.loggedInUser?.id
      })
      .subscribe(() => {
        this.competitionForm.reset();
        this.competitionDialog = false;
      });
  }

  public get roleName(): string {
    return this.authService.loggedInUser?.roleName;
  }
}
