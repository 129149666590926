<div class="grid">
  <div class="col-12">
    <p-toolbar styleClass="mb-4">
      <ng-template pTemplate="left">
        <h5 class="m-0">{{ 'competitions.title' | translate }}</h5>
      </ng-template>
    </p-toolbar>
    <p-dataView #dv [value]="competitions" [rows]="4" layout="grid"
                emptyMessage="{{ 'competitions.noResults' | translate }}">
      <ng-template pTemplate="header">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <div class="my-2">
            <button *ngIf="roleName !== 'Referee'" pButton pRipple label="{{ 'competitions.addCompetitionButton' | translate }}" icon="pi pi-plus"
                    class="p-button-success mr-2" (click)="openNewCompetition(competitionDv)"></button>
          </div>
        </div>
      </ng-template>
      <ng-template let-competitions pTemplate="gridItem">
        <div class="grid grid-nogutter">
          <div class="col-12 md:col-6" *ngFor="let element of competitions"
               [ngClass]="{'inactive-competition': !element.active}">
            <div class="card m-3 border-1 surface-border">
              <div class="flex flex-column mb-3">
                <div *ngIf="element.imgData?.includes('COMPETITIONS')">
                  <img [src]="element.imgData" [alt]="element.competitionName"
                       class="w-9 shadow-2 my-3 mx-0" />
                </div>
                <div *ngIf="!element.imgData.includes('COMPETITIONS')">
                  <img src="assets/demo/images/nature/nature9.jpg" alt="no-img"/>
                </div>
                <div class="text-2xl font-bold">{{ element.competitionName }}</div>
                <span>
          <p-chip label="{{ element.startDate + 'Z' | date:'dd.MM.yyyy, HH:mm' }}"></p-chip> -
          <p-chip label="{{ element.endDate + 'Z' | date:'dd.MM.yyyy, HH:mm' }}"></p-chip>
        </span>
              </div>
              <div class="flex align-items-center justify-content-between flex-wrap">
                <p-button icon="pi pi-info-circle" label="{{ 'competitions.details' | translate }}"
                          class="m-2" (click)="openCompetitionDetail(element, competitionDetailDv)"></p-button>
              </div>
              <div class="flex justify-content-start flex-row">
                <p-toast></p-toast>
                <p-button *ngIf="(!!element.actualEnd || !element.actualStart)" icon="pi pi-arrow-circle-right"
                          severity="info" label="{{ 'competitions.start' | translate }}" class="m-2"
                          (click)="startCompetition($event, element.id)" [disabled]="!element.active"></p-button>
                <p-confirmPopup key="start-competition"></p-confirmPopup>
                <p-button *ngIf="(!!element.actualStart && !element.actualEnd)" icon="pi pi-exclamation-circle"
                          severity="danger" label="{{ 'competitions.stop' | translate }}"
                          (click)="stopCompetition($event, element.id)" class="m-2" [disabled]="!element.active"></p-button>
                <p-confirmPopup key="stop-competition"></p-confirmPopup>
                <p-button *ngIf="competitionNotStarted(element)" icon="pi pi-trash" severity="danger"
                          label="{{ 'competitions.reset' | translate }}"
                          (click)="resetCompetition($event, element.id)" class="m-2" [disabled]="!element.active"></p-button>
                <p-confirmPopup key="reset-competition"></p-confirmPopup>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
    </p-dataView>
  </div>
</div>
<p-dialog #competitionDv [closable]="false" [(visible)]="competitionDialog" [style]="{width: '800px'}"
          header="{{'competitions.modal.competitionTitle' | translate}}" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <form [formGroup]="competitionForm">
      <div class="field">
        <label for="description">{{ 'competitions.description' | translate }}</label>
        <p-editor id="description" [style]="{ height: '320px' }" formControlName="description"></p-editor>
      </div>
      <div class="p-formgrid grid">
        <div class="field col">
          <div class="field">
            <label for="startDate">{{ 'competitions.startDate' | translate }}</label>
            <p-calendar id="startDate" [showTime]="true" [showIcon]="true" formControlName="startDate"
                        [ngClass]="{'ng-invalid ng-dirty' : competitionForm.controls['startDate'].touched && competitionForm.controls['startDate'].invalid}"></p-calendar>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['startDate'].touched && competitionForm.controls['startDate'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="endDate">{{ 'competitions.endDate' | translate }}</label>
            <p-calendar id="endDate" [showTime]="true" [showIcon]="true" formControlName="endDate"
                        [ngClass]="{'ng-invalid ng-dirty' : competitionForm.controls['endDate'].touched && competitionForm.controls['endDate'].invalid}"></p-calendar>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['endDate'].touched && competitionForm.controls['endDate'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="competitionName">{{ 'competitions.competitionName' | translate }}</label>
            <input type="text" pInputText id="competitionName" formControlName="competitionName" required
                   [ngClass]="{'ng-invalid ng-dirty' : competitionForm.controls['competitionName'].touched && competitionForm.controls['competitionName'].invalid}" />
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['competitionName'].touched && competitionForm.controls['competitionName'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
        </div>
        <div class="field col">
          <div class="field">
            <label for="referees">{{ 'competitions.selectReferees' | translate }}</label>
            <p-multiSelect id="referees" [options]="refereeOptions" formControlName="referees"
                           placeholder="{{ 'competitions.selectReferees' | translate }}"
                           [showClear]="true"
                           [class]="competitionForm.controls['referees'].touched && competitionForm.controls['referees'].invalid ? 'ng-invalid ng-dirty' : ''"
            ></p-multiSelect>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['referees'].touched && competitionForm.controls['referees'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
          <div class="field">
            <label for="participants">{{ 'competitions.selectParticipants' | translate }}</label>
            <p-multiSelect id="participants" [options]="participantsOptions" formControlName="competitors"
                           placeholder="{{ 'competitions.selectParticipants' | translate }}"
                           [showClear]="true"
            ></p-multiSelect>
          </div>
          <div class="field">
            <label for="scoringRules">{{ 'competitions.selectScoringRules' | translate }}</label>
            <p-dropdown id="scoringRules" [options]="scoringRulesOptions" formControlName="scoringRule"
                        placeholder="{{ 'competitions.selectScoringRules' | translate }}"
                        [showClear]="true"
                        [class]="competitionForm.controls['scoringRule'].touched && competitionForm.controls['scoringRule'].invalid ? 'ng-invalid ng-dirty' : ''"
            ></p-dropdown>
            <small class="ng-dirty ng-invalid"
                   *ngIf="competitionForm.controls['scoringRule'].touched && competitionForm.controls['scoringRule'].invalid">{{ 'users.form.errors.required' | translate }}</small>
          </div>
        </div>
      </div>

      <div class="field" style="display: flex; align-items: center">
        <label for="active" style="margin-right: 4px">{{ 'users.active' | translate }}</label>
        <p-inputSwitch formControlName="active" id="active" />
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'users.form.cancel' | translate}}" icon="pi pi-times" class="p-button-text"
            (click)="closeCompetitionDialog()"></button>
    <button pButton pRipple label="{{ 'users.form.save' | translate }}" icon="pi pi-check"
            [disabled]="competitionForm.invalid" class="p-button-text" (click)="saveCompetition()"></button>
  </ng-template>
</p-dialog>

<p-dialog #competitionDetailDv [(visible)]="competitionDetailDialog" [style]="{width: '800px'}" [modal]="true" [closable]="false" class="p-fluid">
  <ng-template pTemplate="content">
    <app-competition-details (closeModal)="competitionDetailDialog = false" [competition]="competition"></app-competition-details>
  </ng-template>
</p-dialog>
