import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { forkJoin, interval } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { UploadService } from '../../core/upload.service';
import { User } from '../../users/users.types';
import { CompetitionService } from '../competition.service';
import { Competition } from '../competition.types';

@Component({
  selector: 'app-competition-details',
  templateUrl: './competition-details.component.html',
  styleUrl: './competition-details.component.scss'
})
export class CompetitionDetailsComponent implements OnInit {
  @Input() public competition: Partial<Competition> = {};
  @Output() public closeModal = new EventEmitter<any>();

  public cmmcDialogOpen = false;
  public cmmcData: any;
  public zoomFactor: number = 1;
  public previewImage: HTMLElement | null = null;
  public cols: any[] = [];
  public ranking: any[] = [];
  public additionalRanking: any;
  public rejectionMessage: string | undefined;
  public selectedCaptureId: number | undefined;
  public rankingDialogOpen: boolean = false;
  public confirmRejectDebateDialogOpen: boolean = false;
  public filterTeam: any[] = [];
  public activeFilter: string = 'All';
  public filterSpecies: any[] = [];
  public competitionSpecies: SelectItem[] = [];
  public updateLoading = false;
  public referees: Partial<User[]> | undefined;
  public participants: Partial<User[]> | undefined;
  public length: number | undefined;
  public captures: any[] = [];
  public filteredCaptures: any[] = [];
  public refereeOptions: SelectItem[] = [];
  public participantsOptions: SelectItem[] = [];
  public competitionForm: FormGroup = new FormGroup({
    competitionId: new FormControl(null),
    competitionName: new FormControl(null, { validators: Validators.required }),
    startDate: new FormControl(null, { validators: Validators.required }),
    endDate: new FormControl(null, { validators: Validators.required }),
    scoringRule: new FormControl(null, { validators: Validators.required }),
    referees: new FormControl(null, { validators: Validators.required }),
    competitors: new FormControl(null),
    active: new FormControl(true, { validators: Validators.required })
  });
  trackById(index: number, item: any): number {
    return item.id;
  }
  constructor(
    private competitionService: CompetitionService,
    private uploadService: UploadService,
    private messageService: MessageService,
    private authService: AuthService,
    private renderer: Renderer2
  ) {
    const source = interval(60000);
    source.subscribe(() => this.fetchCaptures('notification'));
  }
  onPreviewOpen(event: any): void {
    setTimeout(() => {
      this.previewImage = document.querySelector(
        '.p-image-preview'
      ) as HTMLElement;

      if (this.previewImage) {
        this.attachZoomListeners(this.previewImage);
      }
    }, 100);
  }

  attachZoomListeners(image: HTMLElement): void {
    this.renderer.listen(image, 'click', (event: MouseEvent) =>
      this.toggleZoom(event, image)
    );
    this.renderer.listen(image, 'mousemove', (event: MouseEvent) =>
      this.zoomOnMouseMove(event, image)
    );
  }

  toggleZoom(event: MouseEvent, image: HTMLElement): void {
    const rect = image.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    const percentX = (offsetX / rect.width) * 100;
    const percentY = (offsetY / rect.height) * 100;
    this.zoomFactor = this.zoomFactor === 1 ? 3 : 1;

    image.style.transform = `rotate(0deg) scale(${this.zoomFactor})`;
    image.style.transformOrigin = `${percentX}% ${percentY}%`;
  }

  zoomOnMouseMove(event: MouseEvent, image: HTMLElement): void {
    if (this.zoomFactor > 1) {
      const rect = image.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;
      const percentX = (offsetX / rect.width) * 100;
      const percentY = (offsetY / rect.height) * 100;

      image.style.transformOrigin = `${percentX}% ${percentY}%`;
    }
  }
  getUniqueUsers(): any[] {
    const uniqueUserNamesMap = new Map<string, User>();

    this.captures.forEach(capture => {
      uniqueUserNamesMap.set(capture.userName, capture);
    });

    return Array.from(uniqueUserNamesMap.values());
  }
  public table = 'General';
  activeIndex = 0;
  getUniqueSpecies(): any[] {
    const uniqueUserNamesMap = new Map<string, User>();

    this.captures.forEach(capture => {
      uniqueUserNamesMap.set(capture.fishType, capture);
    });

    return Array.from(uniqueUserNamesMap.values());
  }

  public activeTable(table: string, activeIndex: number = 0) {
    if (table === 'General') {
      this.activeIndex = 0;
      this.table = 'General';
    } else {
      this.table = '';
      this.activeIndex = activeIndex;
    }
  }

  ngOnInit() {
    this.competitionService.getScoringRules().subscribe(res => {
      const scoreRule = res.find(
        item => item.id === this.competition.scoringRule
      );
      const guidelines = JSON.parse(scoreRule?.guideline as string);
      this.competitionSpecies = guidelines.fishTypes.map((item: any) => ({
        value: item.fishType,
        label: item.fishType
      }));
    });
    forkJoin({
      referees: this.competitionService.getReferees(),
      participants: this.competitionService.getParticipants()
    }).subscribe(({ referees, participants }) => {
      this.referees = referees;
      this.participants = participants;

      this.refereeOptions = referees.map(ref => ({
        value: ref.id,
        label: `${ref.firstName} ${ref.lastName}`
      }));

      this.participantsOptions = participants.map(part => ({
        value: part.id,
        label: `${part.firstName} ${part.lastName}`
      }));

      this.competitionForm.patchValue({
        referees: this.referees,
        competitors: this.participants
      });

      this.competitionService
        .getRankings(this.competition.id as number)
        .subscribe(res => {
          this.cols = [
            { field: 'position', header: '#' },
            { field: 'teamName', header: 'Participant' },
            { field: 'teamScore', header: 'Score' },
            { field: 'bonusPoints', header: 'Bonus' }
          ];
          this.ranking = res;
        });
      this.competitionService
        .getAdditionalRankings(this.competition.id as number)
        .subscribe(res => {
          this.additionalRanking = res;
        });
      this.competitionForm.patchValue({
        competitionId: this.competition.id,
        competitionName: this.competition.competitionName,
        startDate: new Date(this.competition!.startDate!),
        endDate: new Date(this.competition.endDate!),
        scoringRule: this.competition.scoringRule,
        active: this.competition.active,
        referees: this.refereeOptions
          .filter((ref: any) => {
            const array = this.competition.referees?.split('|');
            if (!array?.length) {
              return false;
            }
            return array.some((item: any) => parseInt(item) === ref.value);
          })
          .map(ref => ref.value),
        competitors: this.participantsOptions
          .filter((ref: any) => {
            const array = this.competition.competitors?.split('|');
            if (!array?.length) {
              return false;
            }
            return array.some((item: any) => parseInt(item) === ref.value);
          })
          .map(ref => ref.value)
      });
    });

    this.fetchCaptures();
  }

  public closeParentModal() {
    this.closeModal.emit(true);
  }
  public openRankDialog() {
    this.competitionService
      .getRankings(this.competition.id as number)
      .subscribe(res => {
        this.ranking = res;
        this.rankingDialogOpen = true;
      });
  }
  public openCmmcDialog() {
    this.competitionService
      .getMaxBreedStatistics(this.competition.id as number)
      .subscribe(res => {
        this.cmmcData = res;
        this.cmmcDialogOpen = true;
      });
  }

  public applyFilters(status: string | null = null, loading: boolean = false) {
    if (status) {
      this.activeFilter = status;
    }

    if (loading) {
      this.updateLoading = true;
      setTimeout(() => {
        this.updateLoading = false;
      }, 500);
    }

    let filtered = [...this.captures];

    if (this.activeFilter && this.activeFilter !== 'All') {
      if (this.activeFilter === 'Debate') {
        filtered = filtered.filter((capture: any) => this.isInDebate(capture));
      } else {
        filtered = filtered.filter(
          (capture: any) => capture.status === this.activeFilter
        );
      }
    }

    if (this.filterTeam.length) {
      const teamUsernames = this.filterTeam.map((item: any) => item.userName);
      filtered = filtered.filter((item: any) =>
        teamUsernames.includes(item.userName)
      );
    }

    if (this.filterSpecies.length) {
      const speciesTypes = this.filterSpecies.map((item: any) => item.fishType);
      filtered = filtered.filter((item: any) =>
        speciesTypes.includes(item.fishType)
      );
    }

    this.filteredCaptures = filtered;
  }

  public isInDebate(el: any): boolean {
    return (
      el.debates.filter((item: any) => item.status === 'Pending')?.length > 0
    );
  }
  public getDebateClaim(el: any) {
    return el.debates.find((item: any) => item.status === 'Pending')
      ?.claimMessage;
  }
  public getDebateCreator(el: any) {
    return el.debates.find((item: any) => item.status === 'Pending')
      ?.participantName;
  }

  public debateAccepted(el: any) {
    const activeDebate = el.debates.find(
      (item: any) => item.status === 'Pending'
    );
    if (activeDebate) {
      this.competitionService.updateDebate(activeDebate, 'Approved').subscribe({
        next: () => {
          this.fetchCaptures();
          this.messageService.add({
            id: 'debate-accepted',
            severity: 'success',
            summary: 'Successful',
            detail: 'Contestatia a fost acceptata',
            closable: true,
            life: 5000
          });
        }
      });
    }
  }
  public debateDeclined(el: any) {
    const activeDebate = el.debates.find(
      (item: any) => item.status === 'Pending'
    );
    if (activeDebate) {
      this.competitionService.updateDebate(activeDebate, 'Rejected').subscribe({
        next: () => {
          this.fetchCaptures();
          this.messageService.add({
            id: 'debate-rejected',
            severity: 'success',
            summary: 'Successful',
            detail: 'Contestatia a fost respinsa.',
            closable: true,
            life: 5000
          });
        }
      });
    }
  }

  public updateCompetition() {
    if (!this.competition?.id) {
      return;
    }
    const data = this.competitionForm.getRawValue();
    delete data.competitionId;
    this.competitionService
      .updateCompetition(
        {
          ...data,
          s3Data: this.competition.s3Data,
          actualStart: this.competition.actualStart,
          actualEnd: this.competition.actualEnd,
          id: this.competition.id,
          competitors: data.competitors.join('|'),
          referees: data.referees.join('|'),
          createDate: this.competition.createDate,
          creator: this.competition.creator
        },
        this.competition.id
      )
      .subscribe({
        error: error => {
          console.error(error);
        }
      });
  }

  public sortOptions: SelectItem[] = [
    {
      label: 'Crescator',
      value: 'length'
    },
    {
      label: 'Descrecator',
      value: '!length'
    }
  ];
  public sortOrder: any;
  public sortField: any;
  public onSortChange(event: any) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  public fetchCaptures(type: string | null = null) {
    this.competitionService
      .getCompetitionCaptures(this.competition.id as number)
      .subscribe(result => {
        if (type === 'notification') {
          if (result.length > this.captures.length) {
            this.messageService.add({
              id: 'new-captures-added',
              severity: 'info',
              summary: 'Noi capturi',
              detail:
                'Noi capturi au fost incarcate. Apasa pe "Actualizare" pentru a le vedea',
              life: 1000 * 60
            });
          }
          this.captures = result;
        } else {
          this.filteredCaptures = result;
          this.captures = result;
        }
      });
  }
  public cancelRejection() {
    this.selectedCaptureId = undefined;
    this.rejectionMessage = undefined;
    this.confirmRejectDebateDialogOpen = false;
  }
  public get roleName(): string {
    return this.authService.loggedInUser?.roleName;
  }
  public updateCaptureStatus(id: number, status: string, confirmed = false) {
    if (status === 'Rejected' && !confirmed) {
      this.confirmRejectDebateDialogOpen = true;
      this.selectedCaptureId = id;
    } else if (status === 'Rejected' && confirmed) {
      this.competitionService.updateCaptureStatus(id, status).subscribe(() => {
        const affectedCapture = this.captures.find(
          capture => capture.id === id
        );
        const indexOf = this.captures.indexOf(affectedCapture);
        this.captures[indexOf].status = status;
        this.applyFilters(this.activeFilter);
      });
      if (this.rejectionMessage) {
        this.competitionService
          .updateCaptureRejectedDetails(id, this.rejectionMessage!)
          .subscribe(() => {
            const affectedCapture = this.captures.find(
              capture => capture.id === id
            );
            const indexOf = this.captures.indexOf(affectedCapture);
            this.captures[indexOf].statusDetails = this.rejectionMessage;
            this.rejectionMessage = undefined;
            this.selectedCaptureId = undefined;
            this.confirmRejectDebateDialogOpen = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Statusul a fost actualizat',
              life: 3000
            });
            this.applyFilters(this.activeFilter);
          });
      }
    } else {
      this.competitionService.updateCaptureStatus(id, status).subscribe(() => {
        const affectedCapture = this.captures.find(
          capture => capture.id === id
        );
        const indexOf = this.captures.indexOf(affectedCapture);
        this.captures[indexOf].status = status;
        this.captures[indexOf].statusDetails = undefined;
        this.rejectionMessage = undefined;
        this.selectedCaptureId = undefined;
        this.confirmRejectDebateDialogOpen = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Statusul a fost actualizat',
          life: 3000
        });
      });
      this.competitionService
        .updateCaptureRejectedDetails(id, null)
        .subscribe({ next: () => this.applyFilters(this.activeFilter) });
    }
  }

  public updateLength(id: number, length: any) {
    this.competitionService.updateCaptureLength(id, length).subscribe(() => {
      const affectedCapture = this.captures.find(capture => capture.id === id);
      const indexOf = this.captures.indexOf(affectedCapture);
      this.captures[indexOf].length = length;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Lungimea a fost actualizata',
        life: 3000
      });
    });
  }
  public updateSpecies(id: number, species: any) {
    this.competitionService.updateSpecies(id, species).subscribe(() => {
      const affectedCapture = this.captures.find(capture => capture.id === id);
      const indexOf = this.captures.indexOf(affectedCapture);
      this.captures[indexOf].fishType = species;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Specia a fost actualizata',
        life: 3000
      });
    });
  }

  public onCompetitionImgUpload(event: any) {
    this.uploadService
      .uploadFiles(
        this.competition.id as number,
        'competitions',
        event.files,
        this.competition.s3Data
      )
      .subscribe(res => {
        this.competition.s3Data = res;

        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Actualizat banner competitie',
          life: 3000
        });
      });
  }
  public get S3Url() {
    return environment.s3ApiURL;
  }
}
